<template>
  <div class='flex flex-col lg:flex-row items-stretch min-h-screen'>
    <div class='w-full lg:w-80 lg:flex-shrink-0 px-8 pt-2 pb-8 lg:py-16 text-gray-50 flex flex-col items-start justify-start relative'
      :style='backgroundStyle'>
      <h3
        class='text-lg border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToHome'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        {{ goToHomeButtonText }}
      </h3>
      <h3
        class='text-lg border border-solid border-transparent py-1 pl-1 pr-4 rounded-md hover:border-gray-50 cursor-pointer'
        @click='goToRegistrationGuideline'
        style='margin-left:-1.5rem;'>
        <arrow-narrow-left-icon class='h-4 inline-block' />
        {{ goToGuidelinButtonText }}
      </h3>
      <h1 class='text-2xl lg:text-3xl font-medium mt-2 lg:mt-4'>{{ pageTitle }}</h1>
    </div>
    <div class='overflow-y-auto flex-grow'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div class='px-8 lg:px-12'>
        <h2 class='text-lg font-semibold mb-4 text-gray-900 mt-8 lg:mt-12'>{{ registrationFormBoxTitle }}</h2>
        <div v-for='(term, index) in terms'
          :key='`term-${index}`'
          class='text-xs border rounded-md mb-8 w-full lg:w-1/2'>
          <div class='bg-gray-50 p-4' v-html='term.text'></div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-sm'>
            <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
            <span class='px-1'>{{ term.confirmationText }}</span>
          </label>
        </div>
        <edit-form-field
          class='mb-6 w-full lg:w-96' 
          v-for='formField in registrationFormFields'
          :key='formField.keyName'
          :edit-form-field='formField'
          @update-edit-form-field-value='updateRegistrationFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        <h2 class='text-lg font-semibold mb-4 text-gray-900 mt-8 lg:mt-12'>설문조사1</h2>
        <div class='mb-6 w-full lg:w-96'>
          <label class='uppercase text-sm text-gray-500'>
            본 연수강좌를 어떻게 등록하게 되셨나요? (다중 선택 가능)
          </label>
          <div v-for='channel in selectableMarketingChannels'
            :key='`channel-${channel.id}-${channel.value}`'
            class='mb-1'>
            <label>
              <input type='checkbox'
                class='mr-1'
                :value='channel.value'
                v-model='marketingChannels'>{{channel.title}}
            </label>
          </div>
          <div class='mb-1'>
            <label>
              <input type='checkbox'
                class='mr-1'
                v-model='showCustomMarketingChannelInput'>기타
            </label>
          </div>
          <input
            v-if='showCustomMarketingChannelInput'
            v-model='customMarketingChannelInputValue'
            class='border py-1 px-2 rounded block w-full mt-2'
            type='text'
            placeholder='직접 입력'>
        </div>
        <h2 class='text-lg font-semibold mb-4 text-gray-900 mt-8 lg:mt-12'>설문조사2</h2>
        <div class='mb-6 w-full lg:w-96'>
          <label class='uppercase text-sm text-gray-500'>
            지역을 선택해 주시기 바랍니다.
          </label>
          <select
            v-model='city'
            class='border py-1 px-2 rounded block w-full'>
            <option disabled></option>
            <option v-for='selectableCity in selectableCities'
              :key='`selectableCity-${selectableCity.id}-${selectableCity.value}`'
              :value='selectableCity.value'>
              {{selectableCity.title}}
            </option>
          </select>
        </div>
        <h2 class='text-lg font-semibold mb-4 text-gray-900 mt-8 lg:mt-12'>설문조사3</h2>
        <div class='mb-6 w-full lg:w-96'>
          <label class='uppercase text-sm text-gray-500'>
            현재 진료분야를 선택해 주시기 바랍니다.
          </label>
          <select
            v-model='medicalField'
            class='border py-1 px-2 rounded block w-full mb-2'>
            <option disabled></option>
            <option v-for='selectableMedicalField in selectableMedicalFields'
              :key='`selectableMedicalField-${selectableMedicalField.id}-${selectableMedicalField.value}`'
              :value='selectableMedicalField.value'>
              {{selectableMedicalField.title}}
            </option>
          </select>
          <select
            v-if='medicalField !== "해당없음" && medicalField !== ""'
            v-model='detailedMedicalField'
            class='border py-1 px-2 rounded block w-full'>
            <option disabled></option>
            <option v-for='detailedMedicalField in showingSelectableDetailedMedicalFields'
              :key='`detailedMedicalField-${detailedMedicalField.id}-${detailedMedicalField.value}`'
              :value='detailedMedicalField.value'>
              {{detailedMedicalField.title}}
            </option>
          </select>
        </div>
        <div class='border rounded-t-md bg-gray-50 p-4 w-full lg:w-1/2 mb-6'>
          <h2 class='text-lg font-semibold mb-4 text-gray-900'>{{ registrationFeeBoxTitle }}</h2>
          <div class='flex flex-row justify-between mb-2 text-gray-900'>
            <h4>등록비</h4><p> {{ formattedFeeAmount(registrationFeeAmount) }}</p>
          </div>
          <div class='flex flex-row justify-between mb-2'>
            <h4>교재 구매비</h4><p> {{ formattedFeeAmount(printedTextbookFeeAmount) }}</p>
          </div>
          <div class='flex flex-row justify-between mb-2'>
            <h4>금액 합계</h4><p> {{ formattedFeeAmount(totalFeeAmount) }}</p>
          </div>
          <div class='flex flex-row justify-between mb-2'>
            <h4>계좌 정보</h4>
            <p class='text-gray-900 rounded font-semibold'>우리은행 1005-103-454402</p>
          </div>
          <div class='flex flex-row justify-between mb-2'>
            <h4>예금주</h4>
            <p>가톨릭대학교내과학교실진흥회</p>
          </div>
        </div>
        <button 
          class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
          @click='enterConference'
          :disabled='disabledRegistrationButton'
          :class='disabledRegistrationButtonClass'>
          {{ registrationButtonText }}
        </button>
        <ul class='list-disc list-inside text-red-600 text-xs'>
          <li v-if='needToAgreeToTerms'>개인정보 수집 및 위탁에 동의해 주십시오.</li>
          <li v-if='!registrationData.registrationType'>등록 형태를 선택해 주십시오.</li>
          <li v-if='!registrationData.buyPrintedTextbook'>인쇄본 교재 구매 여부를 선택해 주십시오. </li>
          <li v-if='!profile.extraInfo.medicalProfession'>직군을 입력해 주십시오.</li>
          <li v-if='!profile.licenseNumber'>의사 면허번호를 입력해 주십시오.</li>
          <li v-if='!profile.name'>국문 성함을 입력해 주십시오.</li>
          <li v-if='!profile.hospitalName'>소속(근무처)을 입력해 주십시오.</li>
          <li v-if='!profile.address'>주소를 입력해 주십시오.</li>
          <li v-if='!profile.addressDetail'>상세 주소를 입력해 주십시오.</li>
          <li v-if='!profile.phoneNumber'>휴대폰 번호를 입력해 주십시오.</li>
          <li v-if='!isValidPhoneNumber'>휴대폰 번호를 확인해 주십시오.</li>
          <li v-if='!user.email'>이메일 주소를 입력해 주십시오.</li>
          <li v-if='!registrationData.depositor'>입금자명을 입력해 주십시오.</li>
          <li v-if='!registrationData.expectedDepositDate'>입금 예정일을 선택해 주십시오.</li>
          <li v-if='needToCompleteSurvey'>설문조사를 완료해 주십시오.</li>
        </ul>
      </div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel'  class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
    <modal
      name='registration-done-modal'
      height='640px'
      :click-to-close='false'
      :adaptive='true'
      :scrollable='true'
      classes='relative'>
      <div class='p-8 lg:p-16 overflow-y-auto h-screen'>
        <div class='max-w-2xl'>
          <h1 class='text-3xl font-semibold mb-8'>사전등록 확인</h1>
          <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
            <ul class='list-disc'>
              <li class='py-1'>등록비: {{ formattedFeeAmount(totalFeeAmount) }}</li>
              <li class='py-1'>입금 확인을 위해 반드시 <span class='font-semibold text-red-600'>등록자명(소속)으로 입금</span> 하여 주십시오. <span class='font-semibold'>등록자명과 입금자명이 상이한 경우, 등록자 미상으로 입금 확인이 어렵습니다.</span></li>
              <li class='py-1'><span class='font-semibold text-red-600'>등록비 입금 여부 확인은 영업일 기준 3-5일이 소요</span>됩니다. 등록비 납부 내역이 확인되면 등록 완료 안내 메일이 발송됩니다.</li>
              <li class='py-1'>계좌 정보: <span class='inline-block text-gray-900 rounded font-semibold'>우리은행 1005-103-454402</span></li>
              <li class='py-1'>예금주: 가톨릭대학교내과학교실진흥회</li>
            </ul>
          </div>
          <router-link :to='{name: "Home"}' class='block mt-8 w-full text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>홈으로 가기</router-link>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { ArrowNarrowLeftIcon } from 'vue-tabler-icons'
import EditFormField from '@/components/edit-form/EditFormField.vue'
import numbro    from 'numbro'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import jsonLogic from 'json-logic-js'
import dayjs from 'dayjs'

export default {
  name: 'Registration',
  components: {
    EditFormField,
    ArrowNarrowLeftIcon,
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: true,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      terms: null,
      profile: null,
      user: null,
      registrationData: null,
      membershipData: null,
      isValidPhoneNumber: false,
      // custom for cmccme
      selectableMarketingChannels: [
        {
          id: 1,
          value: '이메일',
          title: '이메일',
        },
        {
          id: 2,
          value: '문자',
          title: '문자',
        },
        {
          id: 3,
          value: '대한의사협회 광고(이메일/문자)',
          title: '대한의사협회 광고(이메일/문자)',
        },
        {
          id: 4,
          value: '대한의사협회 KMA 교육센터 게시판',
          title: '대한의사협회 KMA 교육센터 게시판',
        },
        {
          id: 5,
          value: '유관학회 홈페이지',
          title: '유관학회 홈페이지',
        },
        {
          id: 6,
          value: '블로그',
          title: '블로그',
        },
        {
          id: 7,
          value: '지인 소개',
          title: '지인 소개',
        },
      ],
      marketingChannels: [],
      showCustomMarketingChannelInput: false,
      customMarketingChannelInputValue: '',
      selectableCities: [
        {
          id: 1,
          value: '서울',
          title: '서울',
        },
        {
          id: 2,
          value: '경기',
          title: '경기',
        },
        {
          id: 3,
          value: '인천',
          title: '인천',
        },
        {
          id: 4,
          value: '강원',
          title: '강원',
        },
        {
          id: 5,
          value: '충북',
          title: '충북',
        },
        {
          id: 6,
          value: '충남',
          title: '충남',
        },
        {
          id: 7,
          value: '세종',
          title: '세종',
        },
        {
          id: 8,
          value: '대전',
          title: '대전',
        },
        {
          id: 9,
          value: '전북',
          title: '전북',
        },
        {
          id: 10,
          value: '전남',
          title: '전남',
        },
        {
          id: 11,
          value: '광주',
          title: '광주',
        },
        {
          id: 12,
          value: '경북',
          title: '경북',
        },
        {
          id: 13,
          value: '경남',
          title: '경남',
        },
        {
          id: 14,
          value: '대구',
          title: '대구',
        },
        {
          id: 15,
          value: '부산',
          title: '부산',
        },
        {
          id: 16,
          value: '울산',
          title: '울산',
        },
        {
          id: 17,
          value: '제주',
          title: '제주',
        },
      ],
      city: '',
      selectableMedicalFields: [
        {
          id: 1,
          value: '내과학 계열',
          title: '내과학 계열',
        },
        {
          id: 2,
          value: '외과학 계열',
          title: '외과학 계열',
        },
        {
          id: 3,
          value: '내과학, 외과학 외의 임상의학 계열',
          title: '내과학, 외과학 외의 임상의학 계열',
        },
        {
          id: 4,
          value: '해당없음',
          title: '해당없음',
        },
      ],
      medicalField: '',
      selectableInternalMedicineFields: [
        {
          id: 1,
          value: '가정의학과 Family medicine, FM',
          title: '가정의학과 Family medicine, FM',
        },
        {
          id: 2,
          value: '결핵과 Tuberculosis, TB',
          title: '결핵과 Tuberculosis, TB',
        },
        {
          id: 3,
          value: '내과 Internal medicine, IM',
          title: '내과 Internal medicine, IM',
        },
        {
          id: 4,
          value: '소아청소년과 (구 소아과) Pediatrics, PED',
          title: '소아청소년과 (구 소아과) Pediatrics, PED',
        },
        {
          id: 5,
          value: '신경과 Neurology, NU',
          title: '신경과 Neurology, NU',
        },
        {
          id: 6,
          value: '정신건강의학과 (구 정신과) Psychiatry, NP',
          title: '정신건강의학과 (구 정신과) Psychiatry, NP',
        },
        {
          id: 7,
          value: '재활의학과 Rehabilitation medicine, RM',
          title: '재활의학과 Rehabilitation medicine, RM',
        },
        {
          id: 8,
          value: '피부과 Dermatology, DER',
          title: '피부과 Dermatology, DER',
        },
      ],
      selectableGeneralSurgeryFields: [
        {
          id: 1,
          value: '비뇨의학과(구 비뇨기과) Urology, UR',
          title: '비뇨의학과(구 비뇨기과) Urology, UR',
        },
        {
          id: 2,
          value: '산부인과 Obstetrics & Gynecology, OBGY',
          title: '산부인과 Obstetrics & Gynecology, OBGY',
        },
        {
          id: 3,
          value: '성형외과 Plastic surgery, PS',
          title: '성형외과 Plastic surgery, PS',
        },
        {
          id: 4,
          value: '신경외과 Neurosurgery, NS',
          title: '신경외과 Neurosurgery, NS',
        },
        {
          id: 5,
          value: '안과 Ophthalmology, EY',
          title: '안과 Ophthalmology, EY',
        },
        {
          id: 6,
          value: '외과 General surgery, GS',
          title: '외과 General surgery, GS',
        },
        {
          id: 7,
          value: '이비인후과 Otorhinolaryngology, ENT',
          title: '이비인후과 Otorhinolaryngology, ENT',
        },
        {
          id: 8,
          value: '정형외과 Orthopaedic surgery, OS',
          title: '정형외과 Orthopaedic surgery, OS',
        },
        {
          id: 9,
          value: '흉부외과 Thoracic & cardiovascular surgery, CS',
          title: '흉부외과 Thoracic & cardiovascular surgery, CS',
        },
      ],
      selectableOtherMedicineFields: [
        {
          id: 1,
          value: '마취통증의학과 Anesthesiology, AN',
          title: '마취통증의학과 Anesthesiology, AN',
        },
        {
          id: 2,
          value: '방사선종양학과 Radiation Oncology, RO',
          title: '방사선종양학과 Radiation Oncology, RO',
        },
        {
          id: 3,
          value: '영상의학과 Radiology, DR',
          title: '영상의학과 Radiology, DR',
        },
        {
          id: 4,
          value: '응급의학과 Emergency medicine, EM',
          title: '응급의학과 Emergency medicine, EM',
        },
        {
          id: 5,
          value: '병리과 (구 해부병리과) Pathology, AP',
          title: '병리과 (구 해부병리과) Pathology, AP',
        },
        {
          id: 6,
          value: '세포병리학',
          title: '세포병리학',
        },
        {
          id: 7,
          value: '기생충학',
          title: '기생충학',
        },
        {
          id: 8,
          value: '열대의학',
          title: '열대의학',
        },
        {
          id: 9,
          value: '직업환경의학과(구 산업의학과) Occupational & Environmental medicine, OEM',
          title: '직업환경의학과(구 산업의학과) Occupational & Environmental medicine, OEM',
        },
        {
          id: 10,
          value: '진단검사의학과 (구 임상병리과) Laboratory medicine, LM (or CP)',
          title: '진단검사의학과 (구 임상병리과) Laboratory medicine, LM (or CP)',
        },
        {
          id: 11,
          value: '핵의학과 Nuclear medicine, NM',
          title: '핵의학과 Nuclear medicine, NM',
        },
      ],
      detailedMedicalField: '',
      // custom for cmccme 
    }
  },
  watch: {
    'termsDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'userDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.user = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'totalFeeAmount': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.registrationData.registrationFee = this.formattedFeeAmount(newVal)
        }
      },
      immediate: true,
    },
    'passwordDefaultValue': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.user.password = newVal
        }
      },
      immediate: true,
    },
    'marketingChannels': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.registrationData.marketingChannels = newVal
        }
      },
      immediate: true,
      deep: true
    },
    'showCustomMarketingChannelInput': {
      handler: function (newVal, oldVal) {
        if (!newVal && newVal !== oldVal) {
          let index = this.marketingChannels.findIndex(channel => channel === this.customMarketingChannelInputValue)
          if (index > -1) {
            this.marketingChannels.splice(index, 1)
            this.customMarketingChannelInputValue = ''
          }
        }
      },
      immediate: true,
    },
    'city': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.profile.city = newVal
        }
      },
      immediate: true,
    },
    'customMarketingChannelInputValue': {
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (oldVal) {
            let index = this.marketingChannels.findIndex(channel => channel === oldVal)
            this.marketingChannels.splice(index, 1, newVal)
          } else {
            this.marketingChannels.push(newVal)
          }
        } else {
          let index = this.marketingChannels.findIndex(channel => channel === oldVal)
          if (index > -1) {
            this.marketingChannels.splice(index, 1)
          }
        }
      },
      immediate: true,
    },
    'medicalField': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal === '해당없음') {
            this.detailedMedicalField = '해당없음'
          } else {
            this.detailedMedicalField = ''
          }
        }
      },
      immediate: true,
    },
    'detailedMedicalField': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          if (!newVal || newVal === '해당없음') {
            this.registrationData.medicalField = newVal
          } else {
            this.registrationData.medicalField = this.medicalField + ' - ' + newVal
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapGetters('events', [
      'showingEventName',
      'showingEventRegistrationConfigurations',
      'eventMainThemeColor',
    ]),
    backgroundStyle () {
      return `background-color: ${this.eventMainThemeColor};`
    },
    goToHomeButtonText () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.homeButtonText : 'Back To Conference Site'
    },
    goToGuidelinButtonText () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.guidelineButtonText : 'Guidelin For Registration'
    },
    pageTitle () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.pageTitle : 'Registration'
    },
    registrationFormBoxTitle () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.registrationFormBoxTitle : 'My Registration Details'
    },
    registrationFeeBoxTitle () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.registrationFeeBoxTitle : 'Registration Type & Fee'
    },
    registrationButtonText () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.registrationButtonText : 'Register & Pay Registration Fees'
    },
    userDefault () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.userDefault : {}
    },
    termsDefault () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.termsDefault : {}
    },
    profileDefault () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.profileDefault : {}
    },
    registrationDataDefault () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.registrationDataDefault : {}
    },
    membershipDataDefault () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.membershipDataDefault : {}
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    registrationFormFields () {
      return (this.showingEventRegistrationConfigurations) ? this.showingEventRegistrationConfigurations.registrationFormFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    passwordDefault () {
      return this.showingEventRegistrationConfigurations ? this.showingEventRegistrationConfigurations.passwordDefault : false
    },
    hasDefaultPassword () {
      return this.showingEventRegistrationConfigurations ? this.showingEventRegistrationConfigurations.hasDefaultPassword : false
    },
    passwordDefaultValue () {
      if (this.hasDefaultPassword) {
        if (this.passwordDefault === 'showing_event_name') { 
          return this.showingEventName
        } else if (this.passwordDefault === 'last_four_digits_phone_number') {
          // if hasDefaultPassword and passwordDefault is last_four_digits_phone_number, profileDefault must has phoneNumber
          return this.profile.phoneNumber ? this.profile.phoneNumber.replace(/\D/g, '').slice(-4) : ''
        } else {
          return ''
        }
      } else {
        return ''
      }
    },
    // custom for cmccme
    registrationType () {
      return this.registrationData ? this.registrationData.registrationType : ''
    },
    buyPrintedTextbook () {
      return this.registrationData ? this.registrationData.buyPrintedTextbook : ''
    },
    medicalProfession () {
      return this.profile ? this.profile.extraInfo.medicalProfession : ''
    },
    registrationFeeAmount () {
      if (this.registrationType === 'online_72hours') {
        if (this.medicalProfession.includes('개원의') || this.medicalProfession.includes('봉직의')) {
          return 40000
        } else if (this.medicalProfession.includes('공보의') || this.medicalProfession.includes('군의관')) {
          return 30000
        } else if (this.medicalProfession.includes('전공의') || this.medicalProfession.includes('간호사')) {
          return 30000
        } else {
          return 30000
        }
      } else if (this.registrationType === 'online_2weeks') {
        if (this.medicalProfession.includes('개원의') || this.medicalProfession.includes('봉직의')) {
          return 60000
        } else if (this.medicalProfession.includes('공보의') || this.medicalProfession.includes('군의관')) {
          return 40000
        } else if (this.medicalProfession.includes('전공의') || this.medicalProfession.includes('간호사')) {
          return 40000
        } else {
          return 40000
        }
      } else {
        return 0
      }
    },
    printedTextbookFeeAmount () {
      if (this.buyPrintedTextbook === 'buy_printed_textbook') {
        return 20000
      } else if (this.buyPrintedTextbook === 'dont_buy_printed_textbook') {
        return 0
      } else {
        return 0
      }
    },
    totalFeeAmount () {
      return this.registrationFeeAmount + this.printedTextbookFeeAmount
    },
    showingSelectableDetailedMedicalFields () {
      if (this.medicalField === '내과학 계열') {
        return this.selectableInternalMedicineFields
      } else if (this.medicalField === '외과학 계열') {
        return this.selectableGeneralSurgeryFields
      } else if (this.medicalField === '내과학, 외과학 외의 임상의학 계열') {
        return this.selectableOtherMedicineFields
      } else {
        return []
      }
    },
    // custom for cmccme
    needToAgreeToTerms () {
      return this.terms.some(term => !term.agreed)
    },
    needToCompleteSurvey () {
      return !this.marketingChannels.length ||
             !this.city ||
             !this.medicalField ||
             (this.medicalField !== '해당없음' && !this.detailedMedicalField) 
    },
    hasEmptyUserFields () {
      return this.getDeepValues(this.user).some(value => !value)
    },
    hasEmptyProfileFields () {
      return this.getDeepValues(this.profile).some(value => !value) ||
             (!this.isValidPhoneNumber && this.registrationFormFields.findIndex(field => field.key === 'phone_number') > -1 )
    },
    hasEmptyRegistrationDataFields () {
      return this.getDeepValues(this.registrationData).some(value => !value)
    },
    hasEmptyMembershipDataFields () {
      return this.getDeepValues(this.membershipData).some(value => !value)
    },
    disabledRegistrationButton () {
      return this.needToAgreeToTerms ||
             this.hasEmptyProfileFields ||
             this.hasEmptyUserFields ||
             this.hasEmptyRegistrationDataFields ||
             this.hasEmptyMembershipDataFields ||
             this.needToCompleteSurvey
    },
    disabledRegistrationButtonClass () {
      return this.disabledRegistrationButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    registrationParams () {
      return {
        user: this.user,
        profile: this.profile,
        registrationData: this.registrationData,
        membershipData: this.membershipData,
      }
    },
  },
  methods: {
    ...mapActions('users', [
      'registerMyself',
      'checkTokenStatus',
    ]),
    ...mapActions('registrations', [
      'sendRegistrationEmails'
    ]),
    updateRegistrationFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) {
          this.profile[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            this.profile.address = value.address
            this.profile.postalCode = value.postalCode
          } else if (keyName === 'phone_number') {
            this.profile.phoneNumber = value.phoneNumber
            this.isValidPhoneNumber = value.isValidPhoneNumber
          } else {
            this.profile[arr[0]] = value
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.user[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.user[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    goToHome () {
      this.$router.push({name: 'Home'})
    },
    goToRegistrationGuideline () {
      this.$router.push({name: 'RegistrationGuideline'})
    },
    getDeepValues(object) {
      var values = []
      Object.values(object).forEach(value => {
        if(typeof value === "object" && value) {
          var deepValues = this.getDeepValues(value)
          values = values.concat(deepValues)
        } else {
          values.push(value)
        }
      }) 
      return values
    },
    formattedFeeAmount (feeAmount) {
      return `${numbro(feeAmount).format({
        thousandSeparated: true,
        mantissa: 0,
      })} 원`
    },
    enterConference () {
      this.registerMyself(this.registrationParams).then(() => {
        this.openRegistrationDoneModal()
        this.sendRegistrationEmails({ email_type: 'myself_pending' })
      }).catch((error) => {
        if (error.response.status === 409 && error.response.data.message.toLowerCase().includes('user already exist')) {
          this.$alert('이미 등록된 이메일입니다. 이메일을 확인 후 다시 등록해주세요.', {
            confirmButtonText: '확인',
            type: 'warning'
          })   
        } else if (error.response.status === 409 && error.response.data.message.toLowerCase().includes('license number already exist')) {
          this.$alert('이미 등록된 의사 면허번호입니다. 의사 면허번호를 확인 후 다시 등록해주세요.', {
            confirmButtonText: '확인',
            type: 'warning'
          }) 
        } else {
          console.error(error)
        }
      })
    },
    openRegistrationDoneModal () {
      this.$modal.show('registration-done-modal')
    },
    closeRegistrationDoneModal () {
      this.$modal.hide('registration-done-modal')
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (this.hasValidToken) {
        this.$router.replace({name: 'Home'}).catch(() => {})
      }
    })
  },
  mounted () {
    this.$emit('update:layout', 'empty-page')
  },
  beforeDestroy () {
    this.closeRegistrationDoneModal()
    this.$emit('update:layout', 'default-layout')
  },
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}
</style>
